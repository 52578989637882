.list {
  margin: 0;
  padding: 0 1em;
  font-size: 1em;

  li {
    list-style-type: disc;

    &:not(:last-child) {
      padding-bottom: 0.5em;
    }
  }
}
