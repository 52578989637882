// === BOOTSTRAP Variables ===
$text-color: $c_Mako;
$primaryColor: $c_ChathamsBlue;
$input-color-placeholder: $c_GrayChateau;
$link-color: $c_ChathamsBlue;
$link-hover-color: tint($c_ChathamsBlue, 20%);
$nav-link-hover-bg: $c_ChathamsBlue;
$nav-tabs-link-hover-border-color: transparent;

// === Global ===
$body_bg: url('../img/bg_stripe_pattern.png');

// === Buttons ===
$btn_primary_fg: $c_White;
$btn_primary_bg: $c_ChathamsBlue;
$btn_primary_bd: $c_ChathamsBlue;
$btn_primary_ac: shade($c_ChathamsBlue, 10%);

$btn_secondary_fg: $c_White;
$btn_secondary_bg: $c_Nevada;
$btn_secondary_bd: $c_Nevada;
$btn_secondary_ac: shade($c_Nevada, 10%);

$btn_tertiary_fg: $c_White;
$btn_tertiary_bg: $c_ChelseaCucumber;
$btn_tertiary_bd: $c_ChelseaCucumber;
$btn_tertiary_ac: shade($c_ChelseaCucumber, 10%);

// === Breadcrumb ===
$breadcrumb_active_fg: $c_ChathamsBlue;
$breadcrumb_hover_fg: $c_ChathamsBlue;
$breadcrumb_link_fg: $c_ChathamsBlue;

// === Header ===
$header_top_bg: linear-gradient(0deg, $c_Seashell 45%, $c_White 100%);
$header_supergraphic: none;

// === Flashmessage ===
$flashmessage_info: $c_ChathamsBlue;
$flashmessage_success: $c_ChelseaCucumber;
$flashmessage_danger: $c_Monza;
$flashmessage_warning: $c_Monza;

// === Dropdown Dark ===
$dropdown_dark_fg: $c_White;
$dropdown_dark_bg: $c_ChathamsBlue;
$dropdown_dark_ac: $c_KashmirBlue;

// === Autocomplete token ===
$autocomplete_token_bg: $c_ChathamsBlue;

// === Gallery Element ===
$galleryelement_new: $c_ChathamsBlue;
$galleryelement_bg: $c_ChathamsBlue;

// === Input Selected ===
$input_selected: $c_ChelseaCucumber;

// === Manuel ===
$manual_hover_bg: $c_ChathamsBlue;

// === Input Selected ===
$input_selected_fg: $c_ChelseaCucumber;
$input_selected_bg: tint($c_ChelseaCucumber, 60%);
$input_check_bg: $c_ChathamsBlue;

// Icon Options
$icon_fg: $c_RollingStone;
$icon_active_fg: $c_ChathamsBlue;

// === Dropzone ===
$dropzone_fg: $c_ChathamsBlue;
$dropzone_bg: tint($c_ChathamsBlue, 85%);

// === Boxed whatever ===
$boxed_bg: $c_Nevada;

// === Login Page ===
$login_fg: $c_White;
$login_ln: $c_White;
$login_bg: linear-gradient(270deg, rgba($c_Black, 0) 0%, rgba($c_Black, .5) 100%), url('../img/image_1.jpg');
$login_top_bd: 0;

// === Branding ===
$branding_bd: $c_White;
$branding_fg: $c_White;
$branding_bg: $c_ChathamsBlue;

// === Brands selector ===
$brands_fg: $c_White;
$brands_bg: $c_ChathamsBlue;

// === Sidebar Left ===
$sidebar_left_bg: linear-gradient(180deg, #0c3b6b, $c_ChathamsBlue);

// === Sidebar Right ===
$sidebar_right_bd: $c_White;

// === Navigation ===
$navigation_item_bd: rgba($c_Black, .3);
// $navigation_item_bg: $c_ChathamsBlue;
$navigation_item_bg_hover: $c_Danube;
$navigation_item_bg_active: $c_Danube;

$navigation_item_subnav_bg: shade($c_ChathamsBlue, 30%);
// $navigation_item_subnav_bg_hover: shade($c_ChathamsBlue, 30%);
$navigation_item_subnav_bg_active: shade($c_ChathamsBlue, 30%);

// === Gradients ===
$g_lr--primary: linear-gradient(90deg, rgba($primaryColor, 1), rgba($primaryColor, 0.75));

