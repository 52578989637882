.modal {
  color: $c_Mako;
}

.modal-dialog {
  margin: 5rem auto;
}

.modal-header {
  padding: 0;
  border-bottom: none;
}

.modal-content {
  box-shadow: none;

  .close {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    top: -2.5rem;
    right: -2.5rem;
    // border: 1px solid $c_White;
    border-radius: 2px;
    opacity: 1;
    fill: $c_White;
  }
}

#leaveModal {
  .modal-dialog {
    margin: 5rem auto;
  }

  .modal-content {
    background-color: $c_White !important;
    border-radius: 0.5rem !important;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 0.5rem 1.5rem rgba($c_Mako, 0.5);

    p {
      margin: 0 !important;

      & + p {
        margin-top: 1rem;
      }
    }
  }
}

.solid {
  .modal-dialog {
    margin: 5rem auto;
  }

  .modal-content {
    background-color: $c_Seashell !important;
    border-radius: 0.5rem !important;
    padding: 2rem;
    text-align: left;
    box-shadow: 0 0.5rem 1.5rem rgba($c_Mako, 0.5);

    .close {
      position: static;
      border: 1px solid $c_Black;
      border-radius: 2px;
      opacity: 1;
      fill: $c_White;
    }

    .btn-cancel {
      position: absolute;
      right: 20px;
      background: transparent;
      transition: none;
      border: 1px solid #000;
      line-height: 1rem;
      padding: 4px;

      .icon {
        fill: $c_Black;
      }
    }

    :last-child {
      border-bottom: none;
    }
  }

  p {
    margin-top: 0;
  }

  .modal-header {
    border: none;
    padding: 0 0 1.5rem 0;
  }

  .modal-body {
    border-bottom: 1px solid $c_FrenchGray;
    padding: 0 0 2rem 0;
  }

  .modal-footer-impostor {
    border-top: 1px solid $c_FrenchGray;
    padding: 2rem 0 0 0;
    text-align: right;

    .btn {
      svg {
        fill: $c_White;
      }
    }

    &::before {
      content: ' ';
      height: 1px;
      width: 100%;
      display: block;
      background-color: $c_White;
      margin-top: -2rem;
      margin-bottom: 2rem;
    }
  }

  .modal-footer {
    border-top: 1px solid $c_White;
    padding: 1rem 0 0 0;

    .btn {
      svg {
        fill: $c_White;
      }
    }
  }
}
