.progress {
  display: block;
  margin-bottom: 15px;
  background-color: $c_Seashell;
  box-shadow: 3px 3px 3px $c_FrenchGray inset;
  border-radius: 1rem;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 1.2rem;
  line-height: 2rem;
  color: $c_White;
  text-align: center;
  background-color: $c_BahamaBlue_w15;
  box-shadow: 3px 3px 3px $c_FrenchGray inset;
  transition: width .6s ease;
  border-radius: 1rem;
}

.progress.progress-sm {
  height: 15px;
}

.progress.progress-xs {
  height: 6px;
}

.progress .progress-bar {
  background-color: $primaryColor;
  box-shadow: none;
}

.progress .progress-bar.progress-bar-success {
  background-color: $successColor;
}

.progress .progress-bar.progress-bar-info {
  background-color: $infoColor;
}

.progress .progress-bar.progress-bar-warning {
  background-color: $warningColor;
}

.progress .progress-bar.progress-bar-danger {
  background-color: $problemColor;
}
