body {
  position: relative;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
.h1 {
  font-size: 2.8rem;
  font-weight: normal;
}

h2,
.h2 {
  font-size: 1.9rem;
}

h3,
.h3 {
  font-size: 1.7rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.3rem;
}

h6,
.h6 {
  font-size: 1.2rem;
}

p {
  font-size: 1.4rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
}

label {
  font-weight: normal;
}

.revision {
  margin: 1em 3rem;
  color: $c_RollingStone;
}

::selection {
  color: $c_White;
  background: $c_BahamaBlue_w15;
}
