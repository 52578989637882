.flashMessage {
  $state-list: info danger warning success;
  $state-color-list: $flashmessage_info $flashmessage_danger $flashmessage_warning $flashmessage_success;

  border: 0.1rem solid;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  line-height: 1.3em;
  font-size: 14px;

  &-icon {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 1.6rem;
    height: 1.6rem;
    fill: currentColor;
    margin-right: 0.5rem;
  }

  &.alert-dismissible {
    .close {
      color: inherit;
      position: absolute;
      right: 16px;
      top: 6px;
    }
  }

  @for $i from 1 through length($state-list) {
    &--#{nth($state-list, $i)} {
      color: nth($state-color-list, $i);
      border-color: nth($state-color-list, $i);
      background: lighten(nth($state-color-list, $i), 50%);
    }
  }
}
