.grid-stack {
  .panel-title {
    height: auto;
    font-size: 1.6rem;
    font-weight: normal;
    cursor: move;
  }

  .panel-body {
    overflow-y: auto;
    height: 100%;
  }

  > .grid-stack-item {
    > .grid-stack-item-content {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  .ui-draggable {
    .actions {
      display: block;
    }
  }

  .ui-draggable-disabled {
    .panel-title {
      cursor: auto;
    }

    .actions {
      display: none;
    }
  }
}

body.fullscreen-widget-active {
  overflow: hidden;
  position: fixed;

  .animated {
    animation-fill-mode: none;
    animation-name: none;
  }
}

[data-widget-id='latest_products'],
[data-widget-id='new-products'],
[data-widget-id='new-mediafiles'],
[data-widget-id='objects_need_action'] {
  img {
    margin-right: 20px;
    vertical-align: top;
  }

  [class^='img-wrapper'],
  [class*=' img-wrapper'] {
    margin-right: 20px;

    img {
      margin: 0;
    }
  }
}

[data-widget-id='support'],
[data-widget-id='notifications'] {
  img {
    float: left;
    margin-right: 20px;
  }
}

.donut {
  .percent {
    font-size: 16px;
  }

  circle {
    fill: none;
  }

  &__stop1 {
    stop-color: $c_BahamaBlue_w15;
  }

  &__stop2 {
    stop-color: $c_BahamaBlue_b15;
  }

  .circle-back {
    stroke: $c_Iron;
    stroke-width: 5px;
  }

  .circle-front {
    stroke: url(#quotaDonut__gradient);
    stroke-width: 5px;

    /* 2 * Math.PI * radius + 1  */
    stroke-dasharray: 268.0353755551324px;
    stroke-dashoffset: 268.0353755551324px;
    transition: stroke-dashoffset 0.5s ease;
  }
}

.filesharing {
  &__pie {
    float: left;
  }

  &__info {
    float: left;
    margin-left: 30px;
  }
}

#support-panel {
  img {
    width: 100%;
  }
}
