.btn {
  font-weight: normal;
  line-height: 2.4rem;
  transition: border .25s linear, color .25s linear, background-color .25s linear;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  svg {
    pointer-events: none;
    vertical-align: middle;
  }

  // Button with icon
  //.icon + span {
  //  padding-left: .5em;
  //}

  &-white {
    background-color: #fff;
    padding: 0 12px 0 0;
  }

  &-trans {
    background-color: transparent;
    padding: 0 12px 0 0;
  }

  &.active {
    box-shadow: none;
  }

  &.btn-blue-transparent {
    background: transparent;
    border: 1px solid $c_BahamaBlue !important;
    color: $c_BahamaBlue !important;
  }

  &.btn-blue {
    background: $c_BahamaBlue;
    border: 1px solid $c_BahamaBlue !important;
    color: $c_White !important;
  }

  &.btn-grey-transparent {
    background: transparent;
    border: 1px solid $c_Mako !important;
    color: $c_Mako !important;

    svg {
      fill: $c_Mako !important;
    }
  }

  &.btn-red-transparent {
    background: transparent;
    border: 1px solid $c_Monza !important;
    color: $c_Monza !important;

    svg {
      fill: $c_Monza !important;
    }
  }
}
