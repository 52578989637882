@import '../../bosch/scss/colors';

// *** THEBEN ***

$c_SilverChalice: #afafaf;
$c_SilverSand: #c4c7c8;
$c_Boulder: #787878;
$c_Matisse: #1d5694;
$c_PictonBlue: #4b91e6;
$c_ChathamsBlue: #144a81;
$c_ChathamsBlue_b15: #123c67;

$c_Madison: #0c3b6b;
$c_Nevada: #646c71;

$c_StTropaz: #2b5a93;
$c_KashmirBlue: #4a6e92;
$c_KashmirBlue_w15: #618ab2;
$c_Danube: #708bc9;

$c_ChelseaCucumber: #7bb358;
