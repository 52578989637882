.navbar-left {
  li {
    float: left;
  }

  .toggle-left {
    border-right: 1px solid $c_Iron;
  }

  &__logo {
    margin-top: 7px;
    margin-left: 2rem;
  }
}
