.contentBox {
  background-color: $c_Seashell;
  padding: 2rem 0;


  .row {
    margin: 0;
    padding: 0 !important;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  div[class^='col']:first-child {
    border-right: 1px solid $c_RollingStone;
  }

  p {
    margin: 0 0 0.5rem 0;
  }

  div[class^='col'] {
    padding: 0 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  .link {
    display: block;
    margin-bottom: 0.5rem;
    width: 100%;

  }

  .bottom-align {
    .bcontainer {

    }
  }
}
