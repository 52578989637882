.dataTables__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.dataTables__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.dataTables_length {
  select {
    padding: 5px;
  }
}

.dataTables_info {
}

.dataTables_paginate {
  display: flex;
  height: 38px;

  > span {
    display: flex;
  }

  .ellipsis {
    font-size: 20px;
    margin-left: 10px;
  }
}

.paginate_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 5px 15px;

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

  .icon {
    width: 12px;
    height: 12px;
  }

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}
