.panel {
  border: none;
  border-radius: 0;

  .panel-heading {
    padding: 1rem 0;
    margin: 0 1.5rem;
  }

  .panel-title {
    height: auto;
    font-size: 1.6rem;
    font-weight: normal;
  }

  .panel-footer {
    border-color: $c_Iron;
    font-weight: 400;
    text-transform: uppercase;
    padding: 1.5rem;
  }

  .panel-body .h1 {
    float: none;
  }

  .panel-body .headline {
    display: flex;
    padding: 0 1.5rem 1rem;
    margin: 0 -1.5rem 1.5rem;
    border-bottom: 1px solid $c_Iron;

    .options-right {
      display: flex;
      margin-left: auto;
    }

    .icon {
      transform: rotate(270deg);
      margin-bottom: -3px;
    }
  }

  .actions {
    position: absolute;
    right: 1.5rem;
    top: 1.25rem;
  }
}

.panel-default {
  > {
    .panel-heading {
      border-color: $c_Iron;
      background: $c_White;
      color: $c_RollingStone;
    }

    .panel-footer {
      border-color: #eff2f7;
      background: $c_White;
      color: $c_RollingStone;
    }
  }

  .actions .icon {
    width: 1.2rem;
    height: 1.2rem;
    fill: $defaultColor;
    margin: 0 0.3rem;

    &:hover {
      cursor: pointer;
      fill: $defaultColorHover;
    }
  }
}

.panel-primary {
  border-color: $primaryColor;

  > .panel-heading {
    color: $c_White;
    background-color: $primaryColor;
    border-color: $primaryColor;

    a {
      color: $c_White;

      &:hover {
        color: $c_White;
      }
    }
  }
}

.panel-solid-default > {
  .panel-body {
    background: $defaultColor;
    border: none;
    color: $c_White;
  }

  .panel-footer {
    background: $defaultColor;
    border: none;
    color: $c_White;
  }

  .panel-heading {
    background: $defaultColor;
    border: none;
    color: $c_White;
  }
}

.panel-solid-primary > {
  .panel-body {
    background: $primaryColor;
    color: $c_White;
    border: none;
  }

  .panel-footer {
    background: $primaryColor;
    color: $c_White;
    border: none;
  }

  .panel-heading {
    background: $primaryColor;
    color: $c_White;
    border: none;
  }
}

.panel-solid-success > {
  .panel-body {
    background: $successColor;
    color: $c_White;
    border: none;
  }

  .panel-footer {
    background: $successColor;
    color: $c_White;
    border: none;
  }

  .panel-heading {
    background: $successColor;
    color: $c_White;
    border: none;
  }
}

.panel-solid-warning > {
  .panel-body {
    background: $warningColor;
    color: $c_White;
    border: none;
  }

  .panel-footer {
    background: $warningColor;
    color: $c_White;
    border: none;
  }

  .panel-heading {
    background: $warningColor;
    color: $c_White;
    border: none;
  }
}

.panel-solid-info > {
  .panel-body {
    background: $infoColor;
    color: $c_White;
    border: none;
  }

  .panel-footer {
    background: $infoColor;
    color: $c_White;
    border: none;
  }

  .panel-heading {
    background: $infoColor;
    color: $c_White;
    border: none;
  }
}

.widget-mini {
  position: relative;

  .title {
    font-size: 1.6rem;
    display: block;
  }

  .total {
    font-size: 2em;
    display: block;
    font-weight: 300;
  }
}
