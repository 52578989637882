.brands {
  display: flex;
  flex-wrap: wrap;
  color: $c_Mako;

  // Safari Bootstrap + Flexbox workaround
  &::before,
  &::after {
    content: none;
  }

  &__image {
    padding: 3px;
    background: $c_White;

    img {
      border-radius: 4px;
    }
  }

  input[type=checkbox],
  input[type=radio] {
    display: none;
  }

  figure {
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
  }

  input:checked ~ figure,
  figure:hover {
    figcaption,
    .brands__image {
      color: $c_White;
      background-color: $c_BahamaBlue;
    }
  }

  form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    // Hack, figcaption same height
    > [class='col-*'] {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }

  figcaption {
    flex: 1;
    padding: .5rem 2rem;
    margin-top: 1px;
    margin-bottom: 1.5rem;
    line-height: 2rem;
    background: $c_White;
    text-align: center;
  }
}
