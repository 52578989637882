.js-filterByID {
  * {
    pointer-events: none;
  }

  &.active {
    .checkbox-custom-label::before {
      color: $c_Black;
      content: '\2714'; //fat check-mark
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
}
