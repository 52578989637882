.dropdown {
  &.divider {
    .caret::before {
      display: block;
      width: 1px;
      background-color: $c_White;
    }
  }

  &.dropdown--scrollable {
    .dropdown-menu {
      height: auto;
      width: auto;
      max-height: 192px;
      overflow-x: hidden;
    }
  }

  .dropdown-toggle {
    cursor: pointer;
    text-decoration: none;
  }

  .icon-carret {
    width: .9rem;
    height: .9rem;
    vertical-align: baseline;
  }

  .dropdown-menu {
    padding: 1rem 0 0.5rem 0;
    border: 0;
    margin-top: -1px;

    .divider {
      padding: 0 !important;
    }

    > li {
      display: block;
      border-left: 0;
      padding: 8px 20px;
      white-space: nowrap;
      color: $c_Mako;

      &:hover {
        background-color: #f5f5f5;
      }

      &:first-child {
        border: 0;
      }

      &:last-child {
        border-bottom: 0;
      }

      > a {
        padding: 0;
      }
    }
  }

  .checkbox {
    margin: 0;
  }

  &.dark {
    > span {
      margin-top: -7px;
      display: inline-block;
    }

    .dropdown-toggle {
      float: none !important;
      display: block;
      color: inherit !important;

      &:hover,
      &:active {
        text-decoration: none;
        color: $c_White;
      }
    }

    .dropdown-menu {
      padding: 0;

      > li {
        background-color: $c_LimedSpruce;
        border-top: 1px solid $c_LimedSpruce_w15;
        border-bottom: 1px solid $c_LimedSpruce_b35;

        &:hover,
        &:active {
          background-color: $c_LimedSpruce_b15 !important;
        }
      }

      a {
        color: $c_White !important;
        background-color: transparent !important;
      }
    }

    &.open {
      color: $c_White !important;
      background-color: $c_LimedSpruce !important;

      .icon {
        fill: $c_White !important;
      }
    }
  }
}
