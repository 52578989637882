.branding {
  border-top: 10px solid transparent;

  .inner {
    line-height: $headerInnerHeight;
    padding: 0 2rem;
    box-shadow: 0 2px 8px 0  rgba($c_Black, 0.2);
  }

  a {
    text-decoration: none;
  }

  &__text {
    font-weight: bold;

    &--small {
      display: none;
    }

    &--large {
      display: block;
    }
  }
}

.sidebar-mini-1 {
  .branding {
    &__text {
      &--small {
        display: block;
      }

      &--large {
        display: none;
      }
    }
  }

}
