.customRadioCheckbox {
  $c_backgroundColor: $c_White;
  $c_borderColor: $c_RollingStone;
  $c_checkColor: $c_Black;
  $c_darktheme: $c_Iron;

  label {
    padding-left: 0;
  }

  %checkboxRadio_extend_1 {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
    padding: 0;
  }

  %checkboxRadio_extend_2 {
    content: '';
    background: $c_backgroundColor;
    border: 1px solid $c_borderColor;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    padding: 0;
    margin-right: 10px;
    text-align: center;
    border-radius: 2px;
  }

  .checkbox-custom {

    @extend %checkboxRadio_extend_1;
    opacity: 0;
    position: absolute;

    + .checkbox-custom-label {
      padding-left: 0;
      margin: 0;

      &::before {
        @extend %checkboxRadio_extend_2;
      }

      &.darktheme {
        &::before {
          background: $c_darktheme;
        }
      }
    }

    + * + .checkbox-custom-label {
      padding-left: 0;
      margin: 0;

      &::before {
        @extend %checkboxRadio_extend_2;
      }

      &.darktheme {
        &::before {
          background: $c_darktheme;
        }
      }
    }

    &:checked ~ .checkbox-custom-label::before {
      color: $c_checkColor;
      content: '\2714'; //fat check-mark
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    &:checked ~ .checkbox-custom-label.darktheme::before {
      background-color: $c_darktheme;
    }

    &:focus + .checkbox-custom-label {
      outline: none;
    }
  }

  .radio-custom {

    @extend %checkboxRadio_extend_1;
    opacity: 0;
    position: absolute;

    + .radio-custom-label {
      padding-left: 0;
      margin: 0;

      &::before {
        @extend %checkboxRadio_extend_2;

        border-radius: 50%;
      }
    }

    &:checked + .radio-custom-label::before {
      background: $c_checkColor;
      box-shadow: inset 0 0 0 3px $c_backgroundColor;
    }

    &:focus + .radio-custom-label {
      outline: none;
    }
  }

  .checkbox-custom-label {
    @extend %checkboxRadio_extend_1;

    position: relative;
  }

  .radio-custom-label {
    @extend %checkboxRadio_extend_1;

    position: relative;
  }
}

.radio-beside {
  float: left;
  padding-right: 15px;
}
