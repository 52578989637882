.productstage {
  background: $c_White;
  margin: 0 0 1rem 0;
  padding: 3rem;

  .flags {
    display: flex;
    justify-content: flex-start;
    position: absolute;
  }

  .js-addBasketElements, .js-addTypesTobasket {
    display: flex;
    width: max-content;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  a {
    text-decoration: none;

    .new {
      display: inline-block;
      padding: 0.8rem;
      background: $g_lr--primary;
      color: $c_White;
      width: auto;
      white-space: nowrap;
      padding-right: 10px;
      cursor: initial !important;
    }
  }

  .media-item {
    padding: 10px;
    border: solid 1px $c_GrayChateau;
    margin-right: 30px;
    width: 35%;
  }

  .media-text {
    width: calc(65% - 30px);
    padding-left: 0;
  }

  .header {
    border-bottom: 1px solid $c_FrenchGray;
    margin-left: 0;

    p {
      padding: 1rem 0 !important;
      font-size: 1.5rem;
    }

    p + p {
      text-align: right;
    }

    @media screen and (max-width: 767px) {
      p {
        padding-bottom: 0 !important;
      }

      p + p {
        text-align: left;
        padding-top: 0 !important;
      }
    }

    [class^='col-'] {
      margin: 0;
      padding: 0;
    }
  }

  .content {
    .expand {
      display: inline-block;
      padding: 1rem;
      background: $c_RollingStone;
      color: $c_White;
      width: 3.6rem;
      height: 3.6rem;
      white-space: nowrap;
      position: absolute;
      border-radius: 0.3rem;
      opacity: 0.8;
      cursor: pointer;

      svg {
        fill: $c_White;
      }
    }

    .new + .expand {
      left: 4.2em;
    }

    .wrapper {
      height: 500px;
      cursor: pointer;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        max-height: 230px;
      }

      &:hover {
        .hovercontent {
          display: block !important;
          text-align: left;
          background: rgba($c_BahamaBlue, 0.85);
          padding: 1.5rem 1.5rem;
          min-height: 5rem;
          bottom: calc(1rem + 1px);
          left: calc(0.5rem + 1px);
          color: $c_White;
          position: absolute;
          width: calc(100% - 1rem - 2px);

          h3 {
            color: $c_White;
            margin: 0;
          }

          p {
            margin: 0;
          }
        }
      }
    }
  }

  .videPlayButton {
    z-index: 3;
    position: absolute;
    width: 15%;
  }

  .content {
    p,
    h3 {
      margin: 0;
      color: $c_Mako;
    }

    .formGroup-tag {
      display: inline-block;
      margin-top: 2rem;
    }

    .formGroup-tag + .h1 {
      margin-top: 1rem;
    }

    .h1 {
      margin: 2rem 0 2rem 0 !important;
      float: none !important;
      padding: 0 !important;
      line-height: 2.4rem !important;
    }

    .btn {
      margin-top: 2rem;

      em {
        font-style: normal;
      }
    }

    @media screen and (max-width: 767px) {
      .btn {
        white-space: normal !important;
      }
    }

    p.description {
      margin-top: 3rem;
    }

    .wrapper {
      height: 300px;

      img {
        max-height: 300px;
      }
    }
  }

  .btn-transparent {
    float: right;
    padding: 0 0 0 12px;

    svg {
      fill: $c_RollingStone !important;
    }

    &:hover,
    &.active,
    &:active {
      background-color: transparent;
      box-shadow: none;

      svg {
        fill: $c_BahamaBlue_w15 !important;
      }
    }
  }

  .dropdown {
    width: 3rem;
    display: inline-block;
    float: right;
  }

  .dropdown.open {
    .dropdown-toggle {
      svg {
        fill: $c_BahamaBlue;
      }
    }
  }

  .dropdown-menu {
    height: 15.5rem;
    overflow: hidden;
    overflow-y: auto;
    top: 2.5rem;
    width: 20rem;
    position: absolute;
    margin-left: -16rem;
    z-index: 99;

    a {
      line-height: 2.2;
    }
  }

  .no-zoom {
    .wrapper {
      cursor: default;
    }
  }
}

.js-addBasketElements {
  .add-text {
    display: inline-block;
  }

  .remove-text {
    display: none;
  }

  &.active {
    .add-text {
      display: none;
    }

    .remove-text {
      display: inline-block;
    }
  }
}
