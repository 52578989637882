#main-wrapper {
  transition: all, .2s, ease-in-out;
  overflow: hidden;
}

.main-content-wrapper {
  position: relative;

  & #main-content {
    padding: 1.5rem 3rem 3rem 3rem;
    width: 100%;
    min-height: calc(100vh - #{$headerHeight});

    & .h1 {
      margin: 0;
      padding: 0 10px 40px 10px;
      float: left;
      line-height: 10px;
      font-weight: 300;
      font-size: 2.4rem;
    }

    // .row:first-of-type {
    //   margin-bottom: 15px;
    // }
  }
}

.main-content-toggle-left {
  margin-left: 0;
}

.main-content-toggle-right {
  margin-right: 240px;
}

@media only screen and (max-width: 660px) {
  .main-content-toggle-left {
    margin-left: 660px;
  }
}

@media only screen and (max-width: 479px) {
  /* Main Content */
  #main-content .h1 {
    font-size: 22px;
  }
}
