.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10rem;
  left: 0;
  padding-top: 5rem;
  background-image: linear-gradient(transparent, #282725); //$c_Dune
  color: $c_FrenchGray;

  a {
    color: $c_FrenchGray;

    & + a {
      padding-left: 0.5rem;

      &::before {
        content: '|';
        display: inline-block;
        width: 1rem;
      }
    }
  }

  div + div {
    text-align: right;
  }
}
