@import 'galleryelement';

.gallery {
  &__container {
    display: flex;
    flex-flow: row wrap;
    background: transparent;
    margin: 1rem -5px 1rem -5px;
  }

  &--big {
    .galleryelement {
      width: 100%;

      @media (min-width: $screen-md-min) {
        width: calc(50% - 10px);
      }
    }
  }
}

.dts_label {
  display: none !important;
}
