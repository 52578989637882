//colors
$color_border-qtip: $c_Seashell;
$color_background-qtip: $c_Seashell;
$color_text-qtip: $c_Mako;
$color_titlebar: $c_Iron;
$color_icon-background: $c_Seashell;
$color_icon-border: $c_Mako;
$color_icon-text: $c_Mako;
$color_close-qtip: $c_Mako;
$color_close-text-qtip: $c_Mako;

//fonts
$font_0: Tahoma;
$font_1: sans-serif;

//@extend-elements
//original selectors
//.qtip-titlebar .ui-icon, .qtip-icon .ui-icon
%extend_1 {
  display: block;
  text-indent: -1000em;
  direction: ltr;
}

.qtip {
  position: absolute;
  left: -28000px;
  top: -28000px;
  display: none;
  max-width: 768px;
  min-width: 50px;
  font-size: 1.2rem;
  line-height: 1.2rem;
  direction: ltr;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none;
  padding: 0;
}

.qtip--large {
  width: 768px;
}

.qtip--medium {
  width: 480px;
}

.qtip--small {
  width: 320px;
}

.qtip--xtrasmall {
  width: 160px;
}

.qtip-content {
  position: relative;
  padding: 15px;
  overflow: hidden;
  text-align: left;
  word-wrap: break-word;

  .row {
    margin: 0;
  }

  p {
    margin: 0;

    &.h3 {
      font-weight: 700;
      margin-bottom: 5px;
    }
  }
}

.qtip-titlebar {
  position: relative;
  padding: 5px 35px 5px 10px;
  overflow: hidden;
  border-width: 0 0 1px;
  font-weight: bold;

  .qtip-close {
    right: 4px;
    top: 50%;
    margin-top: -9px;
  }

  .ui-icon {
    @extend %extend_1;
  }

  + .qtip-content {
    border-top-width: 0 !important;
  }
}

.qtip-close {
  position: absolute;
  right: -9px;
  top: -9px;
  z-index: 11;
  cursor: pointer;
  outline: medium none;
  border: 1px solid transparent;
}

.qtip-icon {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 3px;
  text-decoration: none;

  .ui-icon {
    @extend %extend_1;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 3px;
    text-decoration: none;
    width: 18px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    text-indent: 0;
    font: normal bold 10px/13px $font_0, $font_1;
    color: inherit;
    background: transparent none no-repeat -100em -100em;
  }
}

.qtip-default {
  border: 1px solid $color_border-qtip;
  background-color: $color_background-qtip;
  color: $color_text-qtip;

  .qtip-titlebar {
    background-color: $color_titlebar;

    .qtip-close {
      border-color: $color_close-qtip;
      color: $color_close-text-qtip;
    }
  }

  .qtip-icon {
    border-color: $color_icon-border;
    background: $color_icon-background;
    color: $color_icon-text;
  }
}

* html .qtip-titlebar .qtip-close {
  top: 15px;
}

.qtip-bootstrap {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: $c_Mako;
  padding: 1px;
  background-color: $c_White;
  border: 1px solid transparent;
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 0 18px 0 rgba($c_LimedSpruce_b35, 0.25);
  //Instead of the line below you could use @include background-clip($clip)
  background-clip: padding-box;

  .qtip-titlebar {
    padding: 10px;
    margin: 15px 15px 0 15px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    background-color: $c_Iron;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;

    .qtip-close {
      right: 11px;
      top: 45%;
      border-style: none;
    }
  }

  .qtip-content {
    padding: 9px 14px;
  }

  .qtip-icon {
    background: transparent;

    .ui-icon {
      width: auto;
      height: auto;
      float: right;
      font-size: 20px;
      font-weight: bold;
      line-height: 18px;
      color: $color_text-qtip;
      //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      text-shadow: 0 1px 0 $c_White;
      opacity: 0.2;
      filter: alpha(opacity=20);

      &:hover {
        color: $c_Mako;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.4;
        filter: alpha(opacity=40);
      }
    }
  }
}

/* Tips plugin */
.qtip .qtip-tip {
  margin: 0 auto;
  overflow: hidden;
  z-index: 10;
}

/* Opera bug #357 - Incorrect tip position
	https://github.com/Craga89/qTip2/issues/367 */
x:-o-prefocus,
.qtip .qtip-tip {
  visibility: hidden;
}

.qtip .qtip-tip,
.qtip .qtip-tip .qtip-vml,
.qtip .qtip-tip canvas {
  position: absolute;
  color: #123456;
  background: transparent;
  border: 0 dashed transparent;
}

.qtip .qtip-tip canvas {
  top: 0;
  left: 0;
}

.qtip .qtip-tip .qtip-vml {
  behavior: url(#default#VML);
  display: inline-block;
  visibility: visible;
}

.qtip-default .js-isToolTipContent {
  display: block !important;
}
