/*****************************************************
* Map variables from _colormap.scss to CSS selectors *
******************************************************/


// === Super-SuperGraphics-Hack :( ===
@if $header_supergraphic=='none' {
  $headerHeight: 70px;
}
@else {
  $headerHeight: 80px;
}

// === Login ===
&.login {
  background-image: $login_bg;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  border-top: $login_top_bd;
}

// === Breadcrumb ===
.breadcrumb {
  li {
    color: $breadcrumb_active_fg;
  }

  a:hover {
    color: $breadcrumb_active_fg;
  }
}

// === Brands selector ===
.brands {
  input:checked ~ figure,
  figure:hover {
    figcaption,
    .brands__image {
      color: $brands_fg;
      background-color: $brands_bg;
    }
  }
}

// === Default content background ===
&:not(.login) {
  background: $c_Seashell $body_bg;

  .main-content-wrapper {
    @if $header_supergraphic=='none' {
      margin-top: $headerHeight - 10px;
    }
    @else {
      margin-top: $headerHeight;
    }
  }
}

// === Buttons ===
.btn-primary {
  color: $btn_primary_fg;
  background: $btn_primary_bg;
  border-color: $btn_primary_bd;

  &:hover {
    background: tint($btn_primary_bg, 10%);
  }

  &:active,
  &:focus {
    background: shade($btn_primary_bg, 10%);
  }
}

.btn-secondary {
  color: $btn_secondary_fg;
  background: $btn_secondary_bg;
  border-color: $btn_secondary_bd;

  &:hover {
    background: tint($btn_secondary_bg, 30%);
  }

  &:active,
  &:focus {
    background: shade($btn_secondary_bg, 80%);
  }
}

.btn-tertiary {
  color: $btn_tertiary_fg;
  background: $btn_tertiary_bg;
  border-color: $btn_tertiary_bd;

  &:hover {
    background: rgba($c_Black, .2);
  }

  &:active,
  &:focus {
    border-color: $btn_tertiary_ac;
  }
}

.btn {
  svg {
    fill: currentColor;
  }
}

// === Header ===
#header {
  .inner {
    background: $header_top_bg;
  }

  // Header with supergraphic/border?
  @if $header_supergraphic == 'none' {
    &::before {
      content: $header_supergraphic;
    }
  }
  @else {
    &::before {
      content: '';
      background-image: url($header_supergraphic);
    }
  }
}

.header-simple {
  // Header with supergraphic/border?
  @if $header_supergraphic == 'none' {
    &::before {
      content: $header_supergraphic;
    }
  }
  @else {
    &::before {
      content: '';
      background-image: url($header_supergraphic);
      height: 10px;
    }
  }
}

// === Branding ===
.branding {
  color: $branding_fg;
  background-color: $branding_bg;

  // Header with supergraphic/border?
  @if $header_supergraphic == 'none' {
    border-top: 0;
    height: $headerHeight - 10px;
  }
  @else {
    border-top: 10px solid $branding_bd;
    height: $headerHeight;
  }

  .logo {
    color: inherit;
  }
}

// === Branding ===
.navbar-left__logo {
  @if $header_supergraphic == 'none' {
    margin-top: 7px;
  }
  @else {
    margin-top: 0;
  }
}

// === Sidebar Left ===
.sidebar-left {
  background: $sidebar_left_bg;

  .nav {
    border-bottom: 1px solid $navigation_item_bd;

    a {
      border-top: 1px solid $navigation_item_bd;
      // border-bottom: 1px solid tint($navigation_item_bg, 10%);
      // background-color: $navigation_item_bg;

      &:focus {
        background-color: inherit;
      }
    }

    li.active > a {
      color: $c_White;
      background-color: $navigation_item_bg_active;
      border-color: $navigation_item_bg_active;
    }

    li a:hover {
      color: $c_White;
      background-color: $navigation_item_bg_hover;
      border-color: $navigation_item_bg_hover;

      .icon {
        fill: $c_White;
      }
    }
  }

  .nav-dropdown.open > a {
    background-color: $navigation_item_subnav_bg_active;
    // border-color: $navigation_item_bg_active;
  }

  .nav-sub li {
    a {
      background-color: $navigation_item_subnav_bg;
      // border-color: $navigation_item_subnav_bg;
      border-top-width: 0;

      // &:hover {
      //   background-color: $navigation_item_subnav_bg_hover;
      //   border-color: $navigation_item_subnav_bg_hover;
      // }
    }

    // &.active > a {
    //   background-color: $navigation_item_subnav_bg_active;
    //   border-color: $navigation_item_subnav_bg_active;
    // }
  }
}

// === Sidebar Left MINI ===
// &.sidebar-mini-1 .sidebar-left .nav > li.nav-dropdown-open > .nav-sub,
// &.sidebar-mini-1 .sidebar-left .nav > li:hover > .nav-sub {
//   background-color: $c_EbonyClay;
// }

.sidebar-mini-1 .sidebar-left .nav > li.nav-dropdown-open > a,
.sidebar-mini-1 .sidebar-left .nav > li:hover > a {
  color: $c_White;
  background-color: $navigation_item_bg_hover;
  border-color: $navigation_item_bg_hover;
}

// === Sidebar Right ===
.sidebar-right {
  &__header {
    // Header with supergraphic/border?
    @if $header_supergraphic=='none' {
      border-top: 0;
    }
    @else {
      border-top: 10px solid $sidebar_right_bd;
    }
  }
}

// === Navigation ===
// .nav .open > a,
// .nav .open > a:hover,
// .nav .open > a:focus {
//   background-color: $navigation_item_bg_active;
//   border-top: tint($sidebar_left_bg, 10%);
//   border-bottom: shade($sidebar_left_bg, 10%);
// }

.dropdown {
  // === Dark Dropdown ===
  &.dark {
    // background-color: $navigation_item_bg;

    &.open {
      color: $dropdown_dark_fg !important;
      background-color: $dropdown_dark_bg !important;
    }

    .dropdown-menu > li {
      background-color: $dropdown_dark_bg;
      border-top: 1px solid tint($dropdown_dark_bg, 10%);
      border-bottom: 1px solid shade($dropdown_dark_bg, 10%);

      &:hover {
        background-color: $dropdown_dark_ac !important;
        border-color: $dropdown_dark_ac;
      }
    }
  }
}

.gallery .btn.active svg {
  fill: $btn_primary_bg;
}

.galleryelement {
  .btn-transparent:hover {
    color: $galleryelement_bg;
  }

  .content .wrapper:hover .hovercontent {
    background: rgba($galleryelement_bg, .85);
  }

  .new {
    background: $galleryelement_new;
  }

  .link {
    color: $link-color;
  }

  .dropdown.open {
    .dropdown-toggle {
      svg {
        color: $btn_primary_bg;
      }
    }
  }
}

.manual:hover .outterwrapper {
  background-color: $manual_hover_bg;
}

.boxed-dark {
  background-color: $boxed_bg;
}

.tabs.solid-bar .nav-tabs {
  background-color: $btn_secondary_bg;

  li {
    a {
      background-color: $btn_secondary_bg;
    }

    &.active > a {
      background-color: $btn_primary_bg;
    }
  }
}

.flashMessage {
  $state-list: info danger warning success;
  $state-color-list: $flashmessage_info $flashmessage_danger $flashmessage_warning $flashmessage_success;

  @for $i from 1 through length($state-list) {
    &--#{nth($state-list, $i)} {
      color: nth($state-color-list, $i);
      border-color: nth($state-color-list, $i);
      background: tint(nth($state-color-list, $i), 85%);
    }
  }
}

.autoCompleteInput .tokenfield .token {
  border-color: $autocomplete_token_bg;
  background-color: $autocomplete_token_bg;
}

/* Dropzone */
.dropzone {
  color: $dropzone_fg;
  background: $dropzone_bg;
  border: 2px dashed $dropzone_fg;

  .icon-upload {
    fill: $dropzone_fg;
  }
}

/* Input selected */
.polyCheckbox:hover,
.polyCheckbox__state:checked + .polyCheckbox {
  color: $c_White;
  background-color: $input_check_bg;
}

.polyCheckbox--big:hover,
.polyCheckbox__state:checked + .polyCheckbox--big {
  background-color: $input_check_bg;
}


.polyCheckbox--check:hover,
.polyCheckbox__state:checked + .polyCheckbox--check {
  background-color: $input_check_bg;
}

.polyCheckbox--file:hover,
.polyCheckbox__state:checked + .polyCheckbox--file {
  color: inherit;
  background-color: $input_selected_bg;
  border-left: 15px solid $input_selected_fg;
}

.searchTab__options {
  &__right {
    a {
      color: $icon_fg;

      &.active {
        color: $icon_active_fg;
      }
    }
  }
}

// === Datatables ===
.paginate_button {
  color: $btn_primary_fg;
  background: $btn_primary_bg;
  border-color: $btn_primary_bd;

  &.current {
    background-color: $btn_secondary_bg;
  }

  &:hover {
    background: tint($btn_primary_bg, 10%);
  }
}

// === UploadDrop ===
.uploadDrop {
  &__container {
    .dz-preview {
      .dz-upload {
        background: $btn_secondary_bg;
        // background: linear-gradient(to bottom, #777, $btn_primary_bg);
      }
    }
  }
}

.donut__stop1 {
  stop-color: tint($btn_primary_bg, 10%);
}

.donut__stop2 {
  stop-color: shade($btn_primary_bg, 10%);
}

.white-border {
  padding: 20px 20px;
}


// User Behaviour
.statistics {
  background-color: $c_White;
  margin-top: 20px;

  .headlineInfo {
    padding: 20px;

    + div {
      padding: 0 20px 20px;
    }
  }

  &--dropdown {
    display: flex;
    justify-content: flex-end;

    input {
      padding: 4px 10px;
      z-index: 1;
      background: transparent;
      background-color: $c_White;
    }

    span {
      align-self: flex-end;
      position: absolute;
      right: 12px;
      top: 8px;
      cursor: pointer;
      z-index: 5;
    }

    &__wrapper {
      display: flex;
    }
  }
}
