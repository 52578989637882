.sidebar {
  position: fixed;
  z-index: $zindex-navbar;
  min-height: 100%;
  width: $sidebar-left-width;
  transition: all, .1s, ease-in-out;

  .nav {
    margin: 0;

    li {
      margin: 0;
    }

    a {
      text-decoration: none;
      line-height: 3rem;
    }

    .icon {
      width: 1.8rem;
      height: 1.8rem;
      fill: $c_White;
      margin-right: .7rem;
      vertical-align: text-bottom;

      a &:hover {
        fill: $c_Black;
      }
    }

    .nav-dropdown {
      .icon-arrow-right {
        position: absolute;
        top: 1.7rem;
        right: 2rem;
        transition: transform .4s;
      }
    }

    .nav-dropdown.open {
      .icon-arrow-right {
        transform: rotate(90deg);
      }
    }

    .nav-sub {
      display: none;
      list-style: none;
      padding: 0;

      li > a {
        display: block;
        padding: 8px 0 8px 50px;
      }
    }
  }
}

.sidebar .nav .nav-sub .nav-dropdown > a {
  padding-right: 30px;
}

.sidebar .nav .nav-sub > .open > a,
.sidebar .nav .nav-sub > .open > a:focus,
.sidebar .nav .nav-sub > .open > a:hover {
  background-color: transparent;
  border-color: transparent;
}

.sidebar .nav-pills > li > a {
  padding: 1rem 2rem;
  font-size: 1.4rem;
}

.sidebar .nav-footer-links {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;

  > li > a {
    padding: 0;
    border: 0 !important;

    &:hover {
      background: transparent !important;
      text-decoration: underline;
    }
  }

  .nav-dropdown .nav-sub {
    display: block !important;

    li a {
      padding: 0;
      background: transparent !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .icon {
    display: none;
  }
}

.sidebar-mini-1 .nav-footer-links {
  padding-left: 0;
  padding-bottom: 0;

  li.nav-dropdown {
    a {
      padding: 16px 2rem !important;
      background: transparent !important;
    }

    &:hover {
      background: $navigation_item_bg_hover !important;
      padding: 2rem inherit;
    }

    .nav-sub {
      display: none !important;
      position: absolute;
      background-color: $navigation_item_subnav_bg;
      bottom: 0;
      z-index: 1;
      padding-bottom: 0 !important;
    }

    .nav-sub > li > a {
      background: transparent !important;
      padding: 1rem 2rem !important;

      &:hover {
        background: $navigation_item_bg_hover !important;
        text-decoration: none;
      }
    }

    .icon {
      display: block !important;
    }
  }
}

.sidebar-subheader {
  color: rgba($c_White, .5);
  line-height: 30px;
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
  padding: 1rem 2rem 1rem 2rem;
}

.sidebar-left a {
  color: $c_White;
}

.sidebar-left a:focus,
.sidebar-left a:hover {
  color: $c_White;
  background-color: transparent;
}

.sidebar-left .active > a,
.sidebar-left .active > a:focus,
.sidebar-left .active > a:hover {
  color: $c_White;
}

.sidebar-left .nav-sub li.open > a {
  color: $c_White;
  background-color: transparent;
}

.sidebar + form > .main-content-wrapper,
.sidebar + .main-content-wrapper {
  width: 100%;
}

.sidebar-left + form > .main-content-wrapper,
.sidebar-left + .main-content-wrapper {
  margin-left: $sidebar-left-width;
  transition: all, .1s, ease-in-out;
  width: auto;
}

@media (max-width: $screen-xs-max) {
  .sidebar-opened .sidebar-left {
    display: block;
    left: 0;
  }

  .sidebar-opened .sidebar-left + .main-content-wrapper {
    left: $sidebar-left-width;
    overflow: hidden;
  }

  .sidebar-opened {
    overflow-x: hidden;
  }
}

.sidebar {
  display: block;
}

.sidebar-mini-1 .sidebar {
  width: $sidebar-left-mini-width;
  // padding-top: 80px;
  transition: all, .1s, ease-in-out;
}

.sidebar-mini-1 .sidebar .nav > li.nav-dropdown.nav-dropdown-open > a,
.sidebar-mini-1 .sidebar .nav > li.nav-dropdown:hover > a {
  border-bottom-right-radius: 0;
}

.sidebar-mini-1 .sidebar .nav > li.nav-dropdown > a::after {
  display: none;
  content: '';
  top: 12px;
  left: 34px;
  font-size: 1rem;
}

.sidebar-mini-1 .sidebar .nav > li > a {
  padding: 1rem 2rem;
  white-space: nowrap;
  overflow: hidden;

  .icon {
    margin-right: 50px;
    margin-left: 2px;
  }
}

.sidebar-mini-1 .sidebar .nav > li a .badge,
.sidebar-mini-1 .sidebar .nav > li a .label {
  display: none;
}

.sidebar-mini-1 .sidebar .nav > li > a > i:first-child {
  margin-right: 10px;
  width: 22.28px;
}

.sidebar-mini-1 .sidebar .nav > li > .nav-sub {
  position: absolute;
  left: 50px;
  margin-top: 0;
  padding-bottom: 2px;
  display: none !important;
  border-radius: 0 0 4px;
}

.sidebar-mini-1 .sidebar .nav > li.nav-dropdown-open,
.sidebar-mini-1 .sidebar .nav > li:hover {
  width: $sidebar-left-width;
  z-index: 10;

  .icon-arrow-right {
    margin-right: 0 !important;
  }
}

.sidebar-mini-1 .sidebar .nav > li.nav-dropdown-open a,
.sidebar-mini-1 .sidebar .nav > li:hover a {
  display: block;
  overflow: visible;
  white-space: normal;
}

.sidebar-mini-1 .sidebar .nav > li.nav-dropdown-open a .badge,
.sidebar-mini-1 .sidebar .nav > li.nav-dropdown-open a .label,
.sidebar-mini-1 .sidebar .nav > li:hover a .badge,
.sidebar-mini-1 .sidebar .nav > li:hover a .label {
  display: block;
}

.sidebar-mini-1 .sidebar .nav > li.nav-dropdown-open > .nav-sub,
.sidebar-mini-1 .sidebar .nav > li:hover > .nav-sub {
  display: block !important;
  z-index: 10;
}

.sidebar-mini-1 .sidebar .nav-pills > li > a > i {
  margin-right: 10px;
  width: 23px;
}

.sidebar-mini-1 .sidebar .nav-pills > li.nav-dropdown > a {
  padding-right: 10px;
}

.sidebar-mini-1 .sidebar .nav-pills > li > .nav-sub {
  left: $sidebar-left-mini-width;
  width: $sidebar-left-width - $sidebar-left-mini-width;
}

.sidebar-mini-1 .sidebar .nav-pills > li > .nav-sub a {
  padding-right: 5px;
}

.sidebar-mini-1 .sidebar .nav-pills > li > .nav-sub .nav-dropdown > a {
  padding-right: 30px;
}

.sidebar-mini-1 .sidebar .sidebar-subheader,
.sidebar-mini-1 .sidebar .sidebar-profile,
.sidebar-mini-1 .sidebar .sidebar-summary {
  visibility: hidden;
}

.sidebar-mini-1 .navbar .navbar-brand-group {
  width: 100px;
}

.sidebar-mini-1 .navbar .navbar-brand-group .navbar-brand {
  width: 50px;
}

.sidebar-mini-1 .main-content-wrapper {
  margin-left: $sidebar-left-mini-width !important;
  transition: all, .1s, ease-in-out;
}


// @media only screen and (max-width: 660px) {
//   .sidebar-toggle {
//     margin-left: 0;
//     width: 100%;
//   }

//   .main-content-toggle-left {
//     margin-left: 660px;
//   }

//   .user-nav ul li {
//     font-size: 1.2rem;
//   }
// }

// .sidebar div.divider {
//   margin: 10px 0;
//   width: 100%;
//   height: 1px;
//   background: #13161c;
// }
