// *** DEFAULT ***

// Grayscale
$c_Black: #000;
$c_Mako: #404245;
$c_RollingStone: #808285;
$c_FrenchGray: #c3c3c4;
$c_Iron: #e1e1e2;
$c_Seashell: #f1f1f1;
$c_White: #fff;

$c_GrayChateau: #a5a6a7;
$c_Dune: #292623; // Not Sure if OO
$c_EbonyClay: #1d212a; // Not Sure if OK

// Color
$c_BahamaBlue: #005691;
$c_BahamaBlue_w15: tint($c_BahamaBlue, 15%);
$c_BahamaBlue_b15: shade($c_BahamaBlue, 15%);

$c_LimedSpruce_w15: #6c7781;
$c_LimedSpruce: #525f6b;
$c_LimedSpruce_b15: #46515b;
$c_LimedSpruce_b35: #353e46;
$c_ShuttleGray: #525f6b;

// Special
$c_Monza_w85: #fbd9dc;
$c_Monza: #e40421;

$c_SelectYellow_w85: #fff5d9;
$c_SelectYellow: #ffba00;

$c_Lima_w60: #c9e5a6;
$c_Lima: #78be20;

// Button and Banner Colors
$defaultColor: $c_LimedSpruce;
$defaultColorHover: $c_LimedSpruce_b15;
$primaryColor: $c_BahamaBlue;
$primaryColorHover: $c_BahamaBlue_b15;
$successColor: $c_Lima;
$successColorHover: $c_Lima_w60;
$warningColor: $c_Monza;
$warningColorHover: lighten($warningColor, 10%);
$infoColor: $c_BahamaBlue;
$infoColorHover: $c_BahamaBlue_b15;
$problemColor: $c_SelectYellow;
$problemColorHover: $c_SelectYellow_w85;

// $stripesBg: repeating-linear-gradient(
//   135deg,
//   $c_White 0,
//   $c_Iron 1px,
//   $c_Iron 1px,
//   $c_White 2px
// );

$stripesBg: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAIAAADZSiLoAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QjFBODFDQzFGOEZFMTFFNkJGMkFEODY0Q0YyNzg1N0YiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QjFBODFDQzJGOEZFMTFFNkJGMkFEODY0Q0YyNzg1N0YiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpCMUE4MUNCRkY4RkUxMUU2QkYyQUQ4NjRDRjI3ODU3RiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpCMUE4MUNDMEY4RkUxMUU2QkYyQUQ4NjRDRjI3ODU3RiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PseOA3UAAAAhSURBVHjaYvz48eOfP3++fPnCBKF4eHiYIBQLCwtAgAEAUb8RqTi11H0AAAAASUVORK5CYII=');
