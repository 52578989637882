.searchTab {
  padding: 2rem;
  margin-bottom: 2rem;
  min-height: 59px;
  max-height: 59px;

  &__field {
    display: flex;

    div:first-child {
      width: 100%;
    }
  }

  &__icons {
    display: flex;
    justify-content: flex-end;
  }

  &__options {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    // margin-top: 1rem;

    > div {
      cursor: pointer;
      margin-right: 1rem;
    }

    .link:first-child {
      margin-left: -3px;
    }

    .dropdown--sortby:not(.no-view-change) {
      border-right: 1px solid $c_FrenchGray;
      margin-right: 18px;
      padding-right: 18px;
    }

    &__right {
      display: flex;
      margin: 0 0 0 auto !important;
      height: 26px;

      a {
        display: inline-block;
        margin-right: 10px !important;
        padding: 0;
        margin-top: 3px;

        &.btn-transparent {
          margin-top: -4px;
        }
      }

      .dropdown-toggle {
        margin-right: 0 !important;
      }
    }

    .resetAllFilter {
      display: none;
    }

    .select + .resetAllFilter {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  &__btnOptions {
    margin-left: 1rem;
  }

  &__additionalTextarea {
    width: 100%;
    min-height: 9rem;
    margin-bottom: 1rem;
  }

  &__row--toggle {
    transition: height 0.25s ease, opacity 0.5s ease;
    overflow: visible;
    height: 3rem;
    opacity: 1;

    &.masked {
      overflow: hidden;
      height: 0;
      opacity: 0;
    }
  }
}
