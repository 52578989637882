.headlineInfo {
  display: flex;
  border-bottom: 1px solid $c_FrenchGray;
  margin-bottom: 1rem;
  line-height: 200%;

  h2 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  &--required h2::after {
    content: '*';
    color: $c_Monza;
    margin-left: .5rem;
  }

  &__info {
    margin-left: auto;

    &.progressInfo {
      width: 80%;
    }

    .icon-arrow-right {
      transition: transform 0.4s ease 0s, -webkit-transform 0.4s ease 0s;
      width: 1rem;
      height: 1rem;
    }

    .btn-transparent {
      // float: right;
      // padding: 0 0 0 12px;

      svg {
        fill: $c_RollingStone !important;
      }

      &:hover,
      &.active,
      &:active {
        background-color: transparent;
        box-shadow: none;

        svg {
          fill: $galleryelement_bg !important;
        }
      }
    }

    a {
      cursor: pointer;
    }

    ul {
      display: flex;
      list-style: none;
      margin: 0 0 0 auto;
      padding: 0;

      &.dropdown-menu {
        display: none;

        li {
          margin: 0;
        }
      }
    }

    .open > ul.dropdown-menu {
      display: block;
    }

    li {
      position: relative;
      margin-left: 3.5rem;
      font-size: 1.2rem;
      font-weight: normal;

      &::before {
        position: absolute;
        top: .8rem;
        left: -2rem;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        content: ' ';
      }

      &.expire1::before {
        background-color: $c_SelectYellow;
      }

      &.expire2::before {
        background-color: $c_Monza;
      }
    }
  }
}
